<template>
  <div class="dashboard">
    <Welcome />
    <InfoCards />
    <MapAndCustomer />
    <LastCards />
    <Charts />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'dashboard',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // { title: 'Ana Sayfa', route: '/' },
    ]);
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  components: {
    Welcome: () => import('@/components/dashboard/welcome'),
    InfoCards: () => import('@/components/dashboard/info-cards'),
    MapAndCustomer: () => import('@/components/dashboard/map-and-customer'),
    LastCards: () => import('@/components/dashboard/last-cards'),
    Charts: () => import('@/components/dashboard/charts'),
  },
};
</script>
<style lang="scss">
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 2.14rem;
}
</style>
